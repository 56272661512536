import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import './PageHeader.scss';

const PageHeader = ({
    heading,
    description,
    noChevron,
}) => {
    return (
        <section className={`page-header${noChevron ? ' page-header--no-chevron' : ''}`}>
            <div className='page-header__container container'>
                <div className='page-header__content'>
                    <h1 className='page-header__heading'>{heading}</h1>

                    {description && (
                        <p className='page-header__description'>{description}</p>
                    )}
                </div>
            </div>
        </section>
    );
};

PageHeader.propTypes = {
    heading: PropTypes.string.isRequired,
    description: PropTypes.any,
    noChevron: PropTypes.bool,
};

export default PageHeader;