import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import './Toast.scss';

const Toast = ({
    className,
    variant,
    children,
}) => {
    // Class definitions
    const baseClass = 'toast';
    const variantClass = variant ? `toast--${variant}` : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    return (
        <div className={classes}>
            <div className='toast__container'>
                {children}
            </div>
        </div>
    );
};

Toast.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['warning', 'error', 'success']),
    children: PropTypes.node.isRequired,
};

export default Toast;
