/* eslint jsx-a11y/no-onchange: 0 */
import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';

// Component imports
import Toast from '../shared/Toast/Toast';
import ContactField from '../ContactField/ContactField';

// Style imports
import './ContactForm.scss';

const ContactForm = () => {
  const [state, setState] = useState([{
    name: '',
    email: '',
    subject: '',
    message: '',
    formSubmitted: false,
    formError: false,
    submitDisabled: false,
  }]);

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);
  
  function sendEmail() {
    setState({...state, submitDisabled: true});
    
    let templateParams = {
      from_name: state.email,
      to_name: 'parkygames_gmail',
      name: state.name,
      subject: state.subject,
      message: state.message,
    }

    emailjs.send('parkygames_gmail', 'parkygames_gmail', templateParams, 'user_cpHzxzCXIDUFDJ2tNNC9p')
    .then(() => {
      setState(
        {
          ...state,
          formError: false,
          formSubmitted: true,
          submitDisabled: false,
        }
      );
    }, () => {
      setState({...state, formSubmitted: false, formError: true, submitDisabled: false});
    }); 
 };

  return (
    <form
      className='contact-form'
      onSubmit={handleSubmit(sendEmail)}
      noValidate
      ref={formRef}
    >
        <fieldset className='contact-form__fieldset'>
            <legend className='section__heading'>Send a message</legend>

            <ContactField
              labelText='Subject:'
              id='subject'
              type='text'
              onChange={(event) => setState({...state, subject: event.target.value})}
              ref={register({required: true})}
              hasError={!!errors['subject']}
              required
              maxLength={100}
            />

            <ContactField
              labelText='Full name:'
              id='full-name'
              type='text'
              onChange={(event) => setState({...state, name: event.target.value})}
              ref={register({required: true})}
              hasError={!!errors['full-name']}
              required
              maxLength={150}
            />

            <ContactField
              labelText='Email address:'
              id='email-address'
              type='email'
              onChange={(event) => setState({...state, email: event.target.value})}
              ref={register({required: true, pattern: /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
              hasError={!!errors['email-address']}
              required
              maxLength={150}
            />

            <ContactField
              labelText='Message:'
              id='message'
              type='text'
              onChange={(event) => setState({...state, message: event.target.value})}
              ref={register({required: true})}
              hasError={!!errors['message']}
              required
              variant='textarea'
            />

            <input
              className={`contact-form__submit${state.submitDisabled ? ' contact-form__submit--disabled' : ''}`}
              type='submit'
              value='Send'
              disabled={state.submitDisabled}
            />
        </fieldset>
    
        {(state.formSubmitted || state.formError) && (
          <Toast className='m-top--large' variant={state.formError ? 'error' : 'success'}>
            <h3 className='heading'>{state.formError ? 'Message error' : 'Message sent'}</h3>
            <p className='toast__text'>{state.formError ? 'There was an issue submitting your enquiry, please try again.' : "Your enquiry has been submitted, we'll be in contact soon."}</p>
          </Toast>
        )}
    </form>
  );
}

export default ContactForm;