import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Style Imports
import './ContactField.scss';

const ContactField = React.forwardRef(({
    type,
    id,
    labelText,
    onChange,
    value,
    hasError,
    validMessage,
    errorMessage,
    rows,
    variant,
    required,
}, ref) => {
    const [isValid, setIsValid] = useState(false);

    const handleBlur = () => {
        if (!hasError) {
            setIsValid(true);
        }
    };

    const classes = classNames({
        'contact-field': true,
        'contact-field--invalid': hasError,
        'contact-field--valid': isValid && !hasError,
    });

    const Tag = variant ? variant : 'input';

    return (
        <div className={classes}>
            <label className='contact-field__label' htmlFor={id}>
                {labelText}
                {required && (
                    <span className='contact-field__required'>*</span>
                )}
            </label>

            <Tag
                className={`contact-field__input${variant ? ' contact-field__input--textarea' : ''}`}
                type={type}
                name={id}
                id={id}
                value={value}
                onChange={onChange}
                ref={ref}
                aria-invalid={hasError ? true : undefined}
                onBlur={handleBlur}
                rows={rows}
                required={required}
            />

            {(hasError || (!hasError && isValid)) && (
                <div className='contact-field__message'>{hasError ? errorMessage : validMessage}</div>
            )}
        </div>
    );
});

ContactField.defaultProps = {
    validMessage: 'Thank you',
    errorMessage: 'Please check and try again',
};

ContactField.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    hasError: PropTypes.bool,
    validMessage: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    rows: PropTypes.string,
    variant: PropTypes.oneOf(['textarea']),
    required: PropTypes.bool,
};

export default ContactField;