import React from 'react';
import { Link } from 'gatsby';

// Layout Imports
import Default from '../layouts/Default/Default';
import SEO from '../layouts/SEO/SEO';

// Component Imports
import PageHeader from '../components/shared/PageHeader/PageHeader';
import Section from '../components/shared/Section/Section';
import ContactForm from '../components/ContactForm/ContactForm';

const Contact = (props) => {
  return (
    <Default
      location={props.location}
      title='Contact'
    >
      <SEO
        title='Contact'
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <PageHeader
        heading='Contact'
        description={<>The quickest way to get in touch is via social media. Check out the <Link to='/faqs'>FAQs</Link> page to see if your question has already been answered - otherwise send a message with the contact form below and we'll try and get back to you as soon as possible.</>}
      />

      <Section accent>
        <ContactForm />
      </Section>
    </Default>
  );
};

export default Contact;